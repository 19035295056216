<template>
  <div>
    <h5 class="mb-3">
      ตั้งค่ารับของรายตัว
      <span class="text-secondary" style="text-decoration: underline;">(เฉพาะงวด)</span>
      วันที่ <span class="text-primary">{{ roundDate }}</span>
      <small class="text-secondary ml-2">(เปลี่ยนได้ที่แถบด้านบน)</small>
    </h5>

    <div class="row">
      <div class="col-md-6" v-for="bet in marketOpenBets" :key="bet.code">
        <ReceiveNumbers :bet="bet" :data="data" @reload="reloadData" />
      </div>
    </div>
  </div>
</template>
<script>
import { marketOpenBets } from "@/configs/market.config"
import moment from '@/helpers/moment'

import ReceiveNumbers from './ReceiveNumbers'

export default {
  name: 'RoundReceiveNumbers',
  components: {
    ReceiveNumbers
  },
  props: ['data'],
  computed: {
    marketOpenBets() {
      return marketOpenBets
    },
    roundDate() {
      if(!this.data)
        return ''

      return moment(this.data.round.roundDate.date).format("DD/MM/YYYY")
    }
  },
  methods: {
    reloadData() {
      this.$emit('reload')
    }
  }
}
</script>
