<template>
  <div>
    <div class="close-time d-flex justify-content-between mb-2">
      <h6>เวลาปิดของบริษัท</h6>
      <h6 style="width: 250px" class="text-primary">{{ companyCloseDate }}</h6>
    </div>
    <div class="close-time d-flex justify-content-between mb-2">
      <h6>เวลาปิดของคุณ</h6>
      <div style="width: 250px" class="d-flex align-items-center">
        <span v-if="!useCustomClose" class="mr-1 text-secondary">ปิดตามบริษัท</span>
        <span v-else class="mr-1 text-success">{{agentCloseTime}}</span>
        <button :disabled="!permSetting" class="btn btn-sm btn-outline-primary py-0" @click="editCLoseTime">ตั้งเวลาปิดรับ</button>
      </div>
    </div>

    <CloseTimeModal :data="data" :is-show="isShowModal" @close="modalClosed" />
  </div>
</template>
<script>
import moment from '@/helpers/moment'
import CloseTimeModal from './CloseTimeModal'

export default {
  name: 'RoundCloseTime',
  components: {
    CloseTimeModal
  },
  props: ['data'],
  data() {
    return {
      isShowModal: false
    }
  },
  computed: {
    companyCloseDate() {
      if(!this.data)
        return ''

      return moment(this.data?.round?.roundDate.close).format("DD/MM/YYYY HH:mm")
    },
    useCustomClose() {
      if(!this.data)
        return true

      return this.data?.close?.useCustomClose
    },
    agentCloseTime() {
      if(!this.useCustomClose)
        return ''

      const closeTime = this.data?.close?.closeTime || this.data?.round?.roundDate.close
      return moment(closeTime).format("DD/MM/YYYY HH:mm")
    },
    userRole() {
      return this.$store.state.userProfile.userRole
    },
    permSetting() {
      const permission = this.$store.state.account?.permission || {}

      return this.userRole === 'Owner' || permission?.RoundReceive === 'w'
    }
  },
  methods: {
    editCLoseTime() {
      console.log(this.data.close)
      this.isShowModal = true
    },
    modalClosed(needReload) {
      this.isShowModal = false
      if(needReload) {
        this.$emit('reload')
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.close-time {
  width: 500px;
  max-width: 100%;

  h6 {
    margin-bottom: 0
  }
}
</style>
