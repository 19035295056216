<template>
  <b-modal
    id="close-time-modal"
    ref="close-time-modal"
    hide-footer
    size="md"
    title="ตั้งเวลาปิดรับ"
    @show="resetModal"
    @hidden="resetModal"
  >

    <form
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >

      <b-form-group
        label="เวลาปิดของบริษัท"
      >
        <b-form-input
          type="text"
          placeholder="เวลาปิดของบริษัท"
          disabled
          :value="companyCloseDate"
          class="text-primary"
        ></b-form-input>
      </b-form-group>

      <b-form-checkbox
        v-model="useCustomClose"
        name="checkbox-1"
        :value="true"
        :unchecked-value="false"
      >
        ตั้งเวลาปิดของคุณเอง
      </b-form-checkbox>

      <b-form-group
        class="mt-4"
        label="ตั้งเวลาปิดของคุณ"
        v-if="useCustomClose"
      >
        <flat-pickr
          :config="{
            enableTime: true,
            enableSeconds: false,
            time_24hr: true,
            dateFormat: 'Y-m-d H:i:S',
            maxDate: companyCloseDate
          }"
          v-model="inputCloseTime"
          :disabled="!useCustomClose"
          class="form-control"
          :class="[{'text-secondary': !useCustomClose, 'text-success': useCustomClose}]"
        />
      </b-form-group>

      <div class="d-flex justify-content-end">
        <b-button variant="link" size="sm" class="text-secondary mr-3" @click="hideModal">ยกเลิก</b-button>
        <b-button type="submit" variant="primary" size="sm" :disabled="isProcess">{{isProcess ? 'กำลังบันทึก...' : 'บันทึก'}}</b-button>
      </div>
    </form>
  </b-modal>
</template>
<script>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

import moment from '@/helpers/moment'
import RoundService from "@/services/RoundService"
import cAlert from '@/helpers/alert'

export default {
  name: 'CLoseTimeModal',
  components: {
    flatPickr
  },
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      inputCloseTime: null,
      useCustomClose: true,
      needReload: false,
      isProcess: false
    }
  },
  computed: {
    companyCloseDate() {
      if(!this.data)
        return ''

      return moment(this.data.round.roundDate.close).format("YYYY-MM-DD HH:mm:ss")
    },
    roundId() {
      return this.$store.state.globalMarket?.round?.roundId
    },
  },
  watch: {
    isShow() {
      if(this.isShow){
        this.useCustomClose = this.data.close.useCustomClose
        this.inputCloseTime = this.data.close.closeTime || this.companyCloseDate
        this.showModal()
      }else{
        this.hideModal()
      }
    }
  },
  methods: {
    resetModal() {
      this.inputAll = ''
      this.needReload = false
    },
    showModal() {
      this.$refs['close-time-modal'].show()
    },
    hideModal() {
      this.$refs['close-time-modal'].hide()
    },
    handleSubmit() {
      this.isProcess = true
      RoundService.updateCloseTime({
        roundId: this.roundId,
        useCustomClose: this.useCustomClose,
        closeTime: this.inputCloseTime
      })
      .then((response)=>{
        if(response.success) {
          this.needReload = true
          this.hideModal()
          this.$notify({
            type: 'success',
            title: 'สำเร็จ!',
            text: 'บันทึกเสร็จเรียบร้อย',
          })
        }else{
          throw new Error(response.data)
        }
      })
      .catch((e)=>{
        cAlert({
          ...e,
          title: 'ไม่สำเร็จ!',
          text: e?.message || 'แก้ไขข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isProcess = false
      })
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('close', this.needReload)
    })
  }
}
</script>
