<template>
  <b-modal
    id="round-receive-modal"
    ref="round-receive-modal"
    hide-footer
    size="md"
    :title="title"
    @show="resetModal"
    @hidden="resetModal"
  >

    <form
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >
      <b-form-group
        label="กลุ่มหวย"
      >
        <b-form-input
          type="text"
          placeholder="กลุ่มหวย"
          disabled
          v-model="groupTitle"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="หวย"
      >
        <b-form-input
          type="text"
          placeholder="หวย"
          disabled
          v-model="marketTitle"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="งวดวันที่"
      >
        <b-form-input
          type="text"
          placeholder="งวดวันที่"
          disabled
          v-model="roundDate"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="รับของ"
        label-class="text-primary"
        v-slot="{ ariaDescribedby }"
      >
        <table class="table table-sm">
          <thead>
            <tr>
              <th width="50%">เลข</th>
              <th width="50%">ตั้งค่ารับของต่อตัว</th>
            </tr>
            <tr class="bg-light">
              <th></th>
              <th><b-form-input size="sm" v-model="inputAll" :number="true" v-on:keydown="keynumber"></b-form-input></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="bet in marketOpenBets" :key="bet.code">
              <td>
                {{ bet.text }}
              </td>
              <td>
                <b-input-group size="sm">
                  <b-form-input
                    size="sm"
                    v-model="input[bet.code]"
                    :number="true"
                    v-on:keydown="keynumber"
                    required>
                  </b-form-input>
                </b-input-group>
              </td>
            </tr>
          </tbody>
        </table>
      </b-form-group>

      <div class="d-flex justify-content-end">
        <b-button variant="link" size="sm" class="text-secondary mr-3" @click="hideModal">ยกเลิก</b-button>
        <b-button type="submit" variant="primary" size="sm" :disabled="isProcess">{{isProcess ? 'กำลังบันทึก...' : 'บันทึก'}}</b-button>
      </div>
    </form>
  </b-modal>
</template>
<script>
import {marketOpenBets} from '@/configs/market.config'
import RoundService from "@/services/RoundService"

import Swal from 'sweetalert2'
import moment from '@/helpers/moment'
import cAlert from '@/helpers/alert'

const defaultInput = {
  threeNumberTop: '',
  threeNumberTode: '',
  twoNumberTop: '',
  twoNumberBottom: '',
  runTop: '',
  runBottom: ''
}

export default {
  name: 'RoundReceiveModal',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      input: JSON.parse(JSON.stringify(defaultInput)),
      inputAll: '',
      needReload: false,
      isProcess: false
    }
  },
  computed: {
    marketOpenBets() {
      return marketOpenBets
    },
    roundId() {
      return this.$store.state.globalMarket?.round?.roundId
    },
    title() {
      return `ตั้งค่ารับของ วันที่ ${this.roundDate}`
    },
    groupTitle() {
      if(!this.data)
        return ''

      return this.data.round.note.groupTitle
    },
    marketTitle() {
      if(!this.data)
        return ''

      return this.data.round.note.marketTitle
    },
    roundDate() {
      if(!this.data)
        return ''

      return moment(this.data.round.roundDate.date).format("DD/MM/YYYY")
    }
  },
  watch: {
    isShow() {
      if(this.isShow){
        this.showModal()
      }else{
        this.hideModal()
      }
    },
    inputAll() {
      if(this.inputAll > 0) {
        this.marketOpenBets.forEach((bet)=>{
          this.input[bet.code] = this.inputAll
        })
      }
    }
  },
  methods: {
    resetModal() {
      this.inputAll = ''
      this.needReload = false
    },
    showModal() {
      this.input = {
        ...defaultInput,
        ...this.data.receive
      }
      this.$refs['round-receive-modal'].show()
    },
    hideModal() {
      this.$refs['round-receive-modal'].hide()
    },
    handleSubmit() {
      this.isProcess = true
      RoundService.updateRoundReceive({
        roundId: this.roundId,
        receive: this.input
      })
      .then((response)=>{
        if(response.success) {
          this.needReload = true
          this.hideModal()
          this.$notify({
            type: 'success',
            title: 'สำเร็จ!',
            text: 'บันทึกเสร็จเรียบร้อย',
          })
        }else{
          throw new Error(response.data)
        }
      })
      .catch((e)=>{
        cAlert({
          title: 'ไม่สำเร็จ!',
          text: 'แก้ไขข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isProcess = false
      })
    },
    keynumber(event) {
      if(!['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'Tab'].includes(event.key)) {
        event.preventDefault()
      }
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('close', this.needReload)
    })
  }
}
</script>
<style lang="scss" scoped>
.table {
  thead {
    tr {
      th {
        font-size: 90%;
        vertical-align: middle;
      }
    }
  }
  tbody {
    tr {
      td {
        vertical-align: middle;
        line-height: 1;
      }
    }
  }
}
</style>
